<template>
  <div-wrapper class="return-ordlistcnt">
    <header class="inner-head bottom-border">
      <Span class="icon-Btn goback" @handleClick="goBack">
        <back-icon />
      </Span>
      <h1>{{ $t("_lotOrders") }}</h1>
    </header>
    <section class="mid-cnt">
      <div-wrapper class="tab-cnt retunlist lot_orders">
        <div-wrapper class="lot-titlebox">
          <div-wrapper class="item_col">
            <h4>{{ $t("_lotId") }}: {{ $route.params.id }}</h4>
            <span class="text-date"
              >{{ $t("_date") }}: {{ new Date().toDateString() }}</span
            >
          </div-wrapper>
          <div-wrapper class="item_col">
            <span class="total_pkt">{{ orders.length }}</span>
            <span class="total_val">{{ $t("_totalPacket") }}</span>
          </div-wrapper>
        </div-wrapper>
        <ul v-if="orders.length">
          <li class="scan-mark" v-for="(order, index) in orders" :key="index">
            <div-wrapper class="infobox">
              <Span :txt="$t('_orderID')">:</Span>
              <Span>{{ order.order_id }}</Span>
            </div-wrapper>
            <div-wrapper class="infobox" v-if="order.order_date">
              <Span :txt="$t('_returnDate')">:</Span>
              <Span>{{ new Date(order.order_date).toDateString() }}</Span>
            </div-wrapper>
            <div-wrapper
              class="infobox"
              v-if="order.order_status === 'ORDER_MISSING_IN_RETURNED_MANIFEST'"
            >
              <Span
                txt="Return Missing Order"
                :style="{ marginTop: '8px', color: 'orange' }"
              />
            </div-wrapper>
            <TagWithDispute :order="order" @raiseDispute="raiseDispute(order)">
              <Span
                className="flag-tag"
                v-if="order.order_status === 'EXTRA_RETURN_ORDER_DELIVERED'"
                :txt="$t('_extraOrderReturn')"
              />
            </TagWithDispute>
            <slot></slot>
            <Span class="check-mark" />
          </li>
        </ul>
        <no-order-msg v-else :message="$t('_noOrder')" />
      </div-wrapper>
    </section>
    <DisputeModal
      v-if="isDisputeModalOpened"
      :order="disputedOrder"
      @closeModal="isDisputeModalOpened = !isDisputeModalOpened"
    />
    <Popup :message="message" @handleClick="message = ''" :color="color" />
    <Loader v-if="isLoading" />
  </div-wrapper>
</template>
<script>
import TagWithDispute from "@/components/TagWithDispute";
import NoOrderMsg from "@/components/order/NoOrderMsg";
import DivWrapper from "@/components/form/DivWrapper";
import BackIcon from "@/components/svgIcons/BackIcon";
import Loader from "@/components/commonUI/LoaderWrapper";
import Span from "@/components/other/Span";
import Popup from "@/components/Popup";
import ApiRequest from '../services/ApiRequest';
import { BASE_API } from '../utils/constants';
import { GET } from '../utils/HttpStatusCodeConst';
export default {
  components: {
    DisputeModal: () => import("@/components/modals/DisputeModal"),
    TagWithDispute,
    NoOrderMsg,
    DivWrapper,
    BackIcon,
    Loader,
    Span,
    Popup
  },

  data() {
    return {
      isDisputeModalOpened: false,
      disputedOrder: "",
      isLoading: false,
      orders: [],
      message: "",
      color: ""
    };
  },

  created() {
    this.isLoading = !this.isLoading;
    ApiRequest(
      BASE_API,
      `/partner91/getlot/?id=${this.$route.params.id}`,
      GET
    )
      .then((_data) => {
        this.isLoading = !this.isLoading;
        this.orders = _data[0].orders;
        console.log(this.orders);
      })
      .catch(() => {
        this.isLoading = !this.isLoading;
        this.$swal({ text: this.$t("_pleaseTryAgain"), icon: "error" });
      });
  },

  methods: {
    goBack() {
      this.$router.back(-1);
    },

    raiseDispute(_order) {
      this.disputedOrder = _order;
      this.isDisputeModalOpened = !this.isDisputeModalOpened;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_return-order.scss";
.bottom-border {
  border-bottom: 1px solid #dddddd;
}

.lot_orders {
  .lot-titlebox {
    .item_col {
      &:first-child {
        width: 70%;
        padding-right: 10px;
      }
      h4 {
        font-size: 1em;
        color: $mdBlack;
        font-weight: 600;
        margin-bottom: 4px;
        display: block;
      }
      .text-date {
        font-size: 0.75em;
        color: $mdlightBlack;
        display: block;
        width: 100%;
        padding: 0px;
        margin: 0px;
        text-align: left;
        font-weight: normal;
      }

      .total_pkt {
        @extend h4;
        text-align: center;
        width: 100%;
        color: $black;
        font-weight: 500;
        margin-bottom: 0px;
      }

      .total_val {
        @extend .text-date;
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
  .scan-mark {
    .infobox {
      span {
        &:last-child {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
